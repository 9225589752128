.planoFundo {
  justify-items: center;
  align-items: center;
  color: blue;
  background-image: url(https://cdn.pixabay.com/photo/2015/10/14/18/42/spring-988163_960_720.jpg);
  width: 100%, auto;
  height: 100%, auto;
}

.PlanoNoticia {
  margin-top: 15px;
  width: 95%;
  height: 94%;
  justify-items: center;
  align-items: center;
  color: #0061AE;
  background-color: white;
  font-style: normal;
  font-weight: bolder;
  font-size: 17px;
  line-height: 148%;
  border-radius: 5px;
}

.titulo {
  --background: #45B97C;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-size: 17px;
  padding-left: 1px;
}

.verMais {
  font-family: Roboto;
  font-size: 12px;
  text-align: center;
  color: #0061AE;
}

.campoNoticia {
  padding-left: 9px;
}

.verbotao {
  --border-width: 0px;
  --box-shadow: 0px;
}

.noticia {
  text-align: center;
}

.data {
  text-decoration: none;
  font-size: 10px;
  line-height: 148%;
  font-family: Roboto;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: #4A4A4A;
}

.data1 {
  text-decoration: none;
  font-size: 13px;
  line-height: 148%;
  font-family: Roboto;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: #4A4A4A;
}

.noticiaTexto {
  text-decoration: none;
  font-size: 13px;
  line-height: 148%;
  font-family: Roboto;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: #2F80ED;
}