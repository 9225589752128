.tituloCompare {
  --background: #45B97C;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-size: 17px;
  padding-left: 1px;
}

.escolhaRegiao {
  font-family: Roboto;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 8px;  
  font-size: 17px;
  color: #4A4A4A;
}

.box1 {
  font-family: Roboto;
  border-bottom: 0.925817px solid rgba(33, 33, 33, 0.12);
  transform: rotate(-0.09deg);
}

.texto1 {
  font-family: Roboto;
  display: flex;
  padding-left: 10px;  
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 15px;
  color: #4079BC;
}

.texto2 {
  font-family: Roboto;
  padding-left: 10px;  
  font-size: 13px;
  font-weight: bold;
  display: flex;
  color: #4A4A4A;
}

.texto3 {
  font-family: Roboto;
  font-weight: 300;
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
  padding-bottom: 10px;
}
/* 
.texto4 {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 148%;
  display: flex;
  align-items: flex-end;
  text-align: right;
  letter-spacing: 0.02em;
  color: #4A4A4A;
}
 */
