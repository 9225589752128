.tituloPage {
  font-family: Roboto;
  font-style: normal;
  --font-weight: bold;
  /* --color: #4079BC; */
  --background: #F2F2F2;
  border-bottom: 0.925817px solid rgba(33, 33, 33, 0.12);
  display: flex;
  text-align: start;
}

.Titulos {
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 148%;
  letter-spacing: 0.02em;
  color: #4079BC;
  --ion-color: #4079BC;
}

.Titulos1A {
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 148%;
  letter-spacing: 0.02em;
  color: #4079BC;
  --ion-color: #4079BC;
}

.Texto3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 148%;
  color: #4079BC;
  --ion-color: #4079BC;
}

.Texto4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 148%;
  letter-spacing: 0.02em;
  --ion-color: black;
  color: black;
}

.Texto5 {
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 148%;
  letter-spacing: 0.02em;
  --ion-color: black;
  color: black;
}

.Numero {
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 148%;
  letter-spacing: 0.02em;
  --ion-color: #4A4A4A;
  color: #4A4A4A;
}

.Titulos2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 148%;
  /* identical to box height, or 19px */
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.02em;
  /* Fonte Cinza */
  color: #4A4A4A;
}

.subtitulos {
  justify-content: space-between;
  --ion-color: #4A4A4A;
  color: #4A4A4A;
  font-weight: bold;
}

.textoNum {
  font-family: Helvetica;
  font-weight: normal;
  --ion-color: #4A4A4A;
  color: #4A4A4A;
}

.fundoColor {
  --ion-background-color: #F2F2F2;
  background: #F2F2F2;
}

.tituloAcor {
  text-align: center;
  color: #4079BC;
}

.Titulos1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 148%;
  /* identical to box height, or 21px */
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.05em;
  --ion-color: #A3A3A3 ;
  color: #A3A3A3;
}

.campo3 {
  font-family: Roboto;
  font-style: normal;
  border-bottom: 1px solid rgba(33, 33, 33, 0.12);
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  align-items: flex-end;
  --ion-color: #A3A3A3 ;
  color: #A3A3A3;
}

.campo2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  display: flex;
  align-items: flex-end;
  --ion-color: #4A4A4A ;
  color: #4A4A4A;
}