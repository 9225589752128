.grid1 {
  border-bottom: 0.925817px solid rgba(33, 33, 33, 0.12);
  transform: rotate(-0.09deg);
}

.campo1 {
  border-bottom: 0.925817px solid rgba(33, 33, 33, 0.12);
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.text {
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 148%;
  letter-spacing: 0.02em;
  color: #4A4A4A;
}

.text1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 148%;
  letter-spacing: 0.02em;
  color: #4079BC;
  ;
}
.titulo{
  font-family: Roboto;
}

.text2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 148%;
  letter-spacing: 0.02em;
  color: #45B97C
}

.campoDados {
  position: relative;
  top: 50px;
  text-align: center;
  align-items: center;
}

.botaoFechar {
  color: #F79828;
}

/* .grafico {
  left: 0px;
  top: 0px;
  height: max-content;
  width: 90%;
  overflow-x: auto;
}

.chartWrapper {
  height: 300px;
  width: -100px;
  position: relative;
}

.chartWrapper>.graficos {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  width: 500px;
  overflow-x: scroll;
}
.grafios{
  position: relative;
  height: 40px;
  width: 30px;
} */

p {
  text-align: center;
  margin-bottom: 40px !important;
}

.spinner svg {
  width: 19% !important;
  height: 85px !important;
}

.chartAreaWrapper {
  width: 1000px;
  overflow-x: scroll;
}

canvas {
  width: 100% !important;
  height: auto !important;
}

.chartAreaWrapper {
  width: 1000px;
  overflow-x: scroll;
}

.fixed[scrollx=true], div[scrolly=false] {
  position: relative;
  overflow: hidden;
}

.fixed::-webkit-scrollbar {
  display: none;
}

.fixed[scrollx=true] {
  overflow-x: auto;
}

.fixed[scrolly=true] {
  overflow-y: auto;
}

#myFixZone {
  height: 200px;
}

.dotBlue {
  height: 15px;
  width: 15px;
  background-color: #4079BC;
  border-radius: 50%;
  display: inline-block;
  margin-top: 5px;
  margin-right: 5px;
  /* padding-top: 15px; */
}

.dotGreen {
  height: 15px;
  width: 15px;
  background-color: #45B97C;
  border-radius: 50%;
  display: inline-block;
  margin-top: 5px;
  margin-right: 5px;
}

div.a {
  font-size: 12px;
  display: inline-block;
}