.cabecalho {
  background: #45B97C;
  color: white;
  /* height: 10%; */
}

.botaoCabec {
  /* --background: #45B97C; */
  color: white;
  /* height: 91%;
  width: 49%; */
}