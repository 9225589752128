.silidePrimeiro {
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;
}

.titulosPages {
    font-family: Roboto;
    color: #45b67c;
    font-style: normal;
    font-weight: bold;
}

.conteudo {
    font-family: Roboto;
    color: #4a4a4a;
    font-style: normal;
}

.img1 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 400px;
}
.img2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 395px;
}
.img3 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    max-width: 330px;
}
