.titulo {
    --ion-background-color:#45B97C;
    --background: #45B97C;
    --ion-color: white;
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-size: 17px;
    padding-left: 1px;
}

.resultado {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 148%;
    /* identical to box height, or 21px */
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.05em;
    --ion-color: #A3A3A3;
    color: #A3A3A3;
}

.campos1 {
    font-family: Roboto;
    font-style: normal;
    border-bottom:1px solid rgba(33, 33, 33, 0.12);
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    display: flex;
    align-items: flex-end;
    --ion-color: #A3A3A3;
    color: #A3A3A3;
}

.campos2{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    display: flex;
    align-items: flex-end;
    --ion-color: #4A4A4A ;
    color: #4A4A4A;
}