.planoFundo {
  justify-items: center;
  align-items: center;
  color: blue;
  background-image: url(https://cdn.pixabay.com/photo/2015/10/14/18/42/spring-988163_960_720.jpg);
  width: 100%, auto;
  height: 100%, auto;
}

.add-button {
  position: absolute;
  top: 1px;
  left: 1px;
}

.PlanoNoticia {
  color: #0061AE;
  background-color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bolder;
  font-size: 17px;
  line-height: 148%;
  border-radius: 5px;
}



.PlanoSocial {
  margin-top: 15px;
  width: 95%;
  height: 94%;
  justify-items: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  font-family: Roboto;
}

.PlanoServicos {
  margin-top: 15px;
  width: 95%;
  height: 94%;
  justify-items: center;
  align-items: center;
  background-color: white;
  font-family: Roboto;
  border-radius: 5px;
}

.PlanoSecretaria {
  margin-top: 15px;
  width: 95%;
  height: 94%;
  justify-items: center;
  align-items: center;
  color: #0061AE;
  background-color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bolder;
  font-size: 17px;
  line-height: 148%;
  border-radius: 5px;
}

.ouvidoria {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  text-align: center;
  align-content: center;
  align-items: center;
  text-align: center;
}

.OuvidoriaLogo {
  padding-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  text-align: center;
  align-content: center;
  width: 122px;
  height: 95.96px;
}

.social {
  display: flex;
  justify-content: center;
}

.socialText {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 148%;
  /* identical to box height, or 24px */
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: #0061AE;
}

.Texto1 {
  padding-bottom: 50px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 15px;
  line-height: 148%;
  color: #4A4A4A;
}

.Texto2 {
  top: 323.11px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 148%;
  /* or 22px */
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  /* Fonte Cinza */
  color: #4A4A4A;
}

.icone {
  --color: #45B97C;
}

.botao-acesse{
  width: 200px;
  align-items: center;
  left: 50%;
  display: flex;
  justify-content: center;
}

.imgGdf {
  align-items: center;
  height: 70px;
}

.imagem-campo{
  display: flex;
  align-items: center;
  justify-content: center;
}



.imagem1{
  border-radius: 10px !important;
  overflow: hidden;
  max-width: 90%;
  width: 300px;
  height: 150px;
  object-fit: fill;
}
.imagem2{
  border-radius: 10px !important;
  overflow: hidden;
  max-width: 90%;
  width: 300px;
  height: 150px;
  object-fit: cover;
}

.imagem3{
  border-radius: 10px !important;
  overflow: hidden;
  max-width: 90%;
  width: 300px;
  height: 150px;
  object-fit: fill;
}

.campo-texto{

  align-items: center;
  display: flex;
  justify-content: center;
  font-family: Roboto;
  font-size: 14px;
  color: #0061AE;
}

.campo-subtexto{
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: Roboto;
  font-size: 12px;
  color: #4A4A4A;

}